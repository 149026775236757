import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import forgotPassword from "../../services/auth/ForgotPasswordService";
import GlassCard from "../../utils/glassCard";
import validator from "validator";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.REACT_APP_MAIN_IMG_URL})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1976d2",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [email, setEmail] = useState("");

  function handleEmailChange(e) {
    e.preventDefault();
    setEmail(e.target.value);
    validateEmail(e);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  function handleClick(e) {
    e.preventDefault();
    const ForgotPassword = forgotPassword(email);
    if (ForgotPassword.status === "success") {
      setSnackBarMsg("Reset Email sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to send Reset Email");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  const [emailError, setEmailError] = useState("");

  const [btndisabled, setBtndisabled] = useState(true);

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
      setBtndisabled(false);
    } else {
      setEmailError("Enter valid Email!");
      setBtndisabled(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {`${process.env.REACT_APP_NAME} CORPORATE`}
          </Typography>
          <Typography component="h2" variant="h6">
            Forgot Password ?
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleEmailChange}
              helperText={emailError}
              error={emailError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
            />{" "}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleClick}
              disabled={btndisabled}
            >
              SEND RESET EMAIL{" "}
            </Button>{" "}
            <Grid container>
              <Grid item xs>
                <Link
                  variant="body2"
                  to="/signin"
                  style={{
                    textDecoration: "none",
                    float: "right",
                    color: "#1976d2",
                  }}
                >
                  Sign In{" "}
                </Link>{" "}
              </Grid>{" "}
            </Grid>{" "}
          </form>
        </div>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
