import app from "../firebase/FirebaseAppService";
import store from "../../redux/store/store";
import { logOutFailure, logOutSuccess } from "../../redux/actions/logout";
import { authCheckDone, setAuthClaims, userSignedOut } from "../../redux/actions/auth";

export default function signOut() {
    app.auth().signOut().then(() => {
        store.dispatch(logOutSuccess())
    }).catch((err) => {
        store.dispatch(logOutFailure(app.auth().currentUser))
        store.dispatch(authCheckDone())
        store.dispatch(userSignedOut())
        store.dispatch(setAuthClaims(""));
    })
}