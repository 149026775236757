import app from "../firebase/FirebaseAppService";
const db = app.firestore();

export default async function getAllNotifications(){
    try {
        const snapshot = await db.collection("Notifications").doc(app.auth().currentUser.uid).collection("notificationList").where("active","==",true).get();

        if(snapshot.empty){
            return{
                status:"success",
                docData:[]
          }
        }else{
        const docs =  snapshot.docs.map(function(doc) {
            return{
               docData: doc.data(),
               docId:doc.id,
               docRef:doc.ref
            }
            })
            return{
                docs,
                status:"success"
            }
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            profile: {}
        };
    }
}

export async function clearNotification(docRef){
    try {
        await docRef.set({
            active:false
           },{merge:true})
           return{
               status:"success"
           }
    } catch (error) {
        console.log(error)
        return{
            status:"failure"
        }
    }
   

}