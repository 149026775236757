import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
const VerifyEmail = lazy(() => import("./auth/VerifyEmail"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const EnquiryForm = lazy(() =>
  import("./dashboard/enquiry/forms/create/EnquiryForm")
);
const EnquiryListView = lazy(() => import("./dashboard/enquiry/list"));
const ServiceRequestCheckout = lazy(() =>
  import("./dashboard/service-request/forms/create/ServiceRequestCheckout")
);
const SrDetails = lazy(() =>
  import("./dashboard/service-request/forms/view/SrDetails")
);
const ServiceRequestListView = lazy(() =>
  import("./dashboard/service-request/list")
);
const NotFoundView = lazy(() => import("./errors/NotFoundView"));
const Account = lazy(() => import("./dashboard/profile"));
const EnqDetails = lazy(() =>
  import("./dashboard/enquiry/forms/view/EnqDetails")
);
const Help = lazy(() => import("./dashboard/misc/Help"));
const Invoice = lazy(() =>
  import("./dashboard/service-request/forms/view/Invoice")
);
const MyPayments = lazy(() => import("./dashboard/payments/list/MyPayments"));

export default function Routes() {
  return (
    <Suspense fallback={<h4>Loading…</h4>}>
      <Switch>
        <Route exact path="/verify" component={VerifyEmail} />{" "}
        <Route exact path="/enquiry" component={EnquiryForm} />{" "}
        <Route exact path="/sr" component={ServiceRequestCheckout} />{" "}
        <Route exact path="/account" component={Account} />{" "}
        <Route
          exact
          path="/sr-details/:referenceId/:id"
          component={SrDetails}
        />{" "}
        <Route
          exact
          path="/enq-details/:referenceId/:id"
          component={EnqDetails}
        />{" "}
        <Route path="/enquiries" component={EnquiryListView} />{" "}
        <Route path="/service-requests" component={ServiceRequestListView} />{" "}
        <Route path="/help" component={Help} />
        <Route path="/" component={Dashboard} />{" "}
        <Route path="*" component={NotFoundView} />{" "}
      </Switch>
    </Suspense>
  );
}
