import app from "../firebase/FirebaseAppService";

export default function signInWithEmailPassword(email, password) {
    console.log(email + " " + password)

  return  app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            // Signed in
            var user = userCredential.user;
            console.log(user.email);
            return{
                status:"success"
            }
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode + " " + errorMessage);
            return{
                status:"failure"
            }
        });
}