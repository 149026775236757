import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import CircularProgressBar from "./support/progress/CircularProgressBar";

function ProtectedRoute({ component: Component, data, ...rest }) {
  const props = { ...rest };

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        props.authCheck !== "done" ? (
          <CircularProgressBar display={true} />
        ) : props.user.uid ? (
          <Component {...routeProps} {...data} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
}

const mapStateToProps = (state) => state.authReducer;
export default connect(mapStateToProps)(ProtectedRoute);
