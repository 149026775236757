import { Box, BoxProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  glass: {
    backgroundColor: "rgba(255,255,255,0.4)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0.8) ",
    backdropFilter: "blur(7px)",
    boxShadow: "10px 10px 10px rgba(30,30,30,0.1)",
    borderRadius: 20,
    borderLeft: "solid 1px rgba(255,255,255,0.3)",
    borderTop: "solid 1px rgba(255,255,255,0.3)",
  },
});

export interface GlassCardProps extends BoxProps {}

export default function GlassCard(props: GlassCardProps) {
  const { ...rest } = props;
  const classes = useStyles();
  return <Box className={classes.glass} {...rest} />;
}
