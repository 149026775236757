import app from "../firebase/FirebaseAppService";

export default function forgotPassword(email) {
  return  app.auth().sendPasswordResetEmail(email).then(() => {
        return{
            status:"success"
        }
    }).catch(function(error) {
        return{
            status:"failure"
        }
    });
}