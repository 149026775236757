export const userSignedIn = (user) => {
    return {
        type: "user/signedin",
        payload: user,
    };
};

export const userSignedOut = () => {
    return {
        type: "user/signedout",
        payload: null,
    };
};

export const authCheckDone = () => {
    return {
        type: "auth/check/done",
    };
}

export const setAuthClaims = (claims) => {
    return {
        type: "auth/claims",
        payload: claims
    };
}