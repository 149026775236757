const initialState = {
    paymentDetails:[]
}

export default function paymentReducer(state = initialState, action){
        switch (action.type){
            case 'complete/paymentDetails':
                return{
                    ...state,
                    paymentDetails:action.payload
                }
            case 'empty/paymentDetails':
                return{
                    ...state,
                    paymentDetails:[]
                }
            default :
            return state        
        }

}