import app from "../firebase/FirebaseAppService";

const db = app.firestore();

export default async function getTermsConditionsAndPolicy(){
    try {
        const doc = await db.collection("App").doc("metadata").get();
        if(doc.empty){
            return{
                status:"success",
                docData:{
    
                }
            }
        }
        return{
            status:"success",
            docData: doc.data()
        }
    } catch (error) {
        return{
            status:"failure"
        }
    }
}