import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SignOut from "../auth/SignOut";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { HelpSharp, InfoSharp } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListIcon from "@material-ui/icons/List";
import LayersIcon from "@material-ui/icons/Layers";
import AddIcon from "@material-ui/icons/Add";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PaymentIcon from "@material-ui/icons/Payment";
import Collapse from "@material-ui/core/Collapse";
import Routes from "../Routes";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% + ${drawerWidth}px)`,
    marginLeft: -drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Navigation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openRequest, setOpenRequest] = React.useState(false);
  const handleRequestClick = () => {
    setOpenRequest(!openRequest);
  };

  const [openEnquiry, setOpenEnquiry] = React.useState(false);
  const handleEnquiryClick = () => {
    setOpenEnquiry(!openEnquiry);
  };

  return (
    <div className={classes.root} style={{ maxWidth: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`${process.env.REACT_APP_NAME} CORPORATE`}
          </Typography>
          <SignOut />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onBackdropClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <Typography
            color="textPrimary"
            variant="caption"
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {process.env.REACT_APP_NAME} Corporate
          </Typography>{" "}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.drawerRoot}
        >
          <Link
            to="/dashboard"
            style={{ color: "black", textDecoration: "none" }}
            onClick={handleDrawerClose}
          >
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <Link
            to="/account"
            style={{ color: "black", textDecoration: "none" }}
            onClick={handleDrawerClose}
          >
            <ListItem button>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </Link>
          <ListItem button onClick={handleRequestClick}>
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Service Request" />
            {openRequest ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openRequest} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/sr"
                className={classes.link}
                style={{ color: "black", textDecoration: "none" }}
                onClick={handleDrawerClose}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="New Request" />
                </ListItem>
              </Link>
              <Link
                to="/service-requests"
                style={{ color: "black", textDecoration: "none" }}
                onClick={handleDrawerClose}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Service History" />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button onClick={handleEnquiryClick}>
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Enquiry" />
            {openEnquiry ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openEnquiry} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/enquiry"
                style={{ color: "black", textDecoration: "none" }}
                onClick={handleDrawerClose}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="New Enquiry" />
                </ListItem>
              </Link>
              <Link
                to="/enquiries"
                style={{ color: "black", textDecoration: "none" }}
                onClick={handleDrawerClose}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Enquiry History" />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Divider />
          <ListItem button onClick={handleDrawerClose}>
            <ListItemIcon>
              <HelpSharp />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>
          <ListItem button onClick={handleDrawerClose}>
            <ListItemIcon>
              <InfoSharp />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{ maxWidth: "100%" }}
      >
        <div className={classes.toolbar} />
        <div className={classes.drawerHeader} />
        {/* <div style={{ height: "500px" }}> */}
        <Routes />
        {/* </div> */}
      </main>
    </div>
  );
}
