const initialState = {
    productDetails: {},
    serviceDetails: {},
    addressDetails: {},
    otherCategory: false,
    workFlowActiveState: 0,
    workFlowStatus: "",
    newSrList: []
}

export default function srReducer(state = initialState, action) {
    switch (action.type) {
        case 'complete/productDetails':
            return {
                ...state,
                productDetails: action.payload
            }
        case 'complete/serviceDetails':
            return {
                ...state,
                serviceDetails: action.payload
            }
        case 'complete/addressDetails':
            return {
                ...state,
                addressDetails: action.payload
            }
        case 'empty/sr':
            return {
                ...state,
                productDetails: {},
                addressDetails: {},
                serviceDetails: {},
                workFlowActiveState: 0
            }
        case 'activestate/sr':
            return {
                ...state,
                workFlowActiveState: action.payload.step,
                workFlowStatus: action.payload.status
            }
        case 'category/other':
            return {
                ...state,
                otherCategory: action.payload
            }
        case 'fetch/newSrList':
            return {
                ...state,
                newSrList: action.payload
            }
        case 'empty/newSrList':
            return {
                ...state,
                newSrList: []
            }
        default:
            return state
    }
}