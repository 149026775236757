import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./components/auth/SignIn";
import SignOut from "./components/auth/SignOut";
import ForgotPassword from "./components/auth/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import App from "./App";
import { SnackbarProvider, useSnackbar } from "notistack";
import app from "./services/firebase/FirebaseAppService";
import TermsandConditions from "./components/dashboard/TCandPolicy/TermsAndConditions";
import Policy from "./components/dashboard/TCandPolicy/Policy";

app.messaging().onMessage((payload) => {
  console.log('Message received. ', payload);
  const notificationTitle = payload.notification.title;
   const notificationOptions = {
   body: payload.notification.body,
   };
  //eslint-disable-next-line no-restricted-globals
  return self.registration.showNotification(
    notificationTitle,
    notificationOptions
  );
});

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/termsandconditions" component={TermsandConditions} />
            <Route path="/policies" component={Policy} />
            <Route exact path="/signin" component={SignIn} />{" "}
            <Route exact path="/signout" component={SignOut} />{" "}
            <Route exact path="/forgot" component={ForgotPassword} />{" "}
            <ProtectedRoute component={App} isAuthorized={true} />{" "}
          </Switch>{" "}
        </Router>{" "}
      </Provider>{" "}
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
