const initialState = {
    profileDetails: {}
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case 'profile/fill':
            return {
                ...state,
                profileDetails: action.payload
            }
        
        default:
            return state
    }
}