import { useEffect, useState } from "react";
import getTermsConditionsAndPolicy from "../../../services/terms&conditions-policy";

export default function Policy() {
  const [policy, setPolicy] = useState("");

  useEffect(() => {
    createMarkup();
  }, []);

  async function createMarkup() {
    const policies = await getTermsConditionsAndPolicy();
    const formattedTnc = policies.docData.policy.replaceAll("\n", "<br/>");
    setPolicy(formattedTnc);
  }

  function test() {
    return {
      __html: policy,
    };
  }
  return (
    <>
      <h2
        style={{ textAlign: "center", fontFamily: '"Gill Sans", sans-serif' }}
      >
        Privacy Policy
      </h2>
      <div style={{ margin: "0 20%" }} dangerouslySetInnerHTML={test()} />
    </>
  );
}
